import React from "react";
import moment from "moment";

export default function AssessmentScore(props) {
  let { getAssessmentReportByEmpIDData, crrSelectedEmp, EditDetailsFn, EditButtonFn, createdAt } = props;


  const cirleColor = (element) => {
    return (<span
      style={{
        background:
          (element && element.colorCode
            ? element.colorCode
            : "#DDDDDD")
      }}
      className={"w-5 h-5 rounded-full "}
    />)
  }

  const barWidthColor = (element) => {
    return (<span
      style={{
        background:
          (element && element.colorCode
            ? element.colorCode
            : "#DDDDDD"),
      }}
      className={
        "w-5 h-5 rounded-full "
      }
    />)
  }

  const shortSummary = (element, index) => {
    //console.log("element--->",element)

    return (<div className="flex items-center px-2 space-x-4">
      {barWidthColor(element)}

      <div className="flex">
        <p className="text-base font-normal">
          <b className="pr-2">{(index + 1).toString() + ". "}
            {EditDetailsFn(element, 'dimension', element.dimension)}:
          </b>
          {/* {element &&
            element.details &&
            element.details.desc
              ? element.details.desc
              : ""} */}
          {EditDetailsFn(element, 'desc', element.details.desc)}
        </p>
      </div>
    </div>)
  }




  let textList1 = [
    "Read every word of the report. Reflect on it and understand your uniqueness. Think about a time when your talent was in use and how did it affect your performance. Keep making notes on the same.",
    "Focus on the Dominant themes as it has a huge influence on your behaviour. It not only allows you to achieve more but also creates your personal brand. People see your behaviour but you can now see from where these behaviour comes and thus help you create a authentic perception of yourself.",
    "You should also give attention to your non-dominant talent themes as it allows you to understand where you will need to partner with someone and what are some areas you might not be naturally gifted.",
    "Once you understand your inherent talent, try making a plan to use it proactively for your next task and see how it helped you being better in it.",
    "In Case you want more insights and clarity, feel free to write to us at contact@happyplus.in so that we can set up a coaching call with you to explain/clarify questions you have."
  ]

  let textList2 = [
    "Keep your Dominant talents in front of you & before you undertake any task, think of the ways you are going to use it.",
    "Share the report with your friends and close ones and ask them to reflect it with you.",
    "Share it with your manager to help them understand what makes you tick and help you leverage it much more.",
    "Share it with your colleagues so that they can leverage unique talents which you bring on table.",
  ]

  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }


  return (
    <>
      {/* Top 5 TalentPlusStrengths report */}

      <main className="py-10 space-y-10 bg-white">
        <section className="flex flex-col items-center justify-between space-y-10 ">
          <div className="flex flex-col items-center space-y-8 text-center">
            <div className="flex justify-center pt-2">
              <img src="/img/assessment/TalentPlusStrengths_logo.svg" className="w-56" />
            </div>
            <div className="py-2 space-y-4 text-center">
              <h1 className="text-6xl text-[#3D405B] font-normal text-center items-center justify-center">
                {EditButtonFn('page_1_title_1', 'TALENTPLUSSTRENGTHS')} <br />ASSESSMENT REPORT
              </h1>
              <h1 className="text-lg font-semibold text-[#212121]/85 italic">
                {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""}{" "} | {createdAt ? moment(createdAt).format("DD MMMM YYYY") : ""}
              </h1>
            </div>
            <div className="flex justify-center pt-6">
              <img src="/img/assessment/talentplus_assessment_report_v2.webp" className="w-full h-full mx-auto" />
            </div>
            <div className="text-center">
              <p className="text-lg text-[#212121] font-medium">
                {EditButtonFn('page_1_footer_text_1', 'TalentPlusStrengths  Online Assessments Copyright 2025')}
              </p>
              <p className="text-base font-medium text-[#212121]/70 italic">
                {EditButtonFn('page_1_confidential_text_1', 'This report is confidential and should not be distributed without permission')}
              </p>
            </div>
          </div>
        </section>
      </main>

      <main className="p-10 px-6 space-y-6 bg-white">
        <div className="px-12 space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-semibold">{EditButtonFn('TalentAssessmentReport', 'Top 5 TalentPlusStrengths  Assessment Report')}
              {/* Talent Assessment Report */}
            </h1>
            <p className="text-lg font-semibold">
              {(crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "")} | {createdAt}
            </p>
          </div>


          <div className="space-y-4">
            {/* Your TalentPlusStrengths  Assessment Report  */}
            {/* <h1 className="text-3xl font-medium text-slate-900">
              {EditButtonFn('page_2_title_2', 'Your TalentPlusStrengths  Assessment Report')} </h1> */}

            <p className="text-[#212121]"> {EditButtonFn('page_2_desc_1', 'Your talents are the essence of who you are, shaping how you think, feel, and act. Understanding and leveraging these talents are key to success. They enable you to find your flow, excel at work, and stand out authentically. Embracing your uniqueness leads to fulfillment and happiness, allowing you to be your true self.')}
              <br />
              <br />
              {EditButtonFn('page_2_desc_2', 'Based on your response to the TalentPlusStrengths  assessment, this report has been created for you. Idea is that you go through the result, reflect on the same and be aware of your uniqueness. Once aware, you can start utilising it proactively to add appropriate skills & knowledge to do anything you want to do in life. It also will help you understand how do you think, relate, act and lead in your life. All these four buckets are the basic structure of making you successful.')}
            </p>

            <div className="w-full space-y-1 page-break">
              <h1 className="text-3xl font-light text-[#212121]">
                <b>{EditButtonFn('page_2_title_3', "How to go through this report:")}</b>
              </h1>
            </div>

            <div className="space-y-1 text-base">
              {textList1 && textList1.length > 0 ?
                textList1.map((text, index6) => (
                  <>
                    <div key={index6} className={`flex p-3 ${index6 % 2 === 0 ? 'bg-[#F5F5F5]' : 'bg-[#E2E2E2]'}`}>
                      <img src={"/img/icon/dot.png"} className="w-2 h-2 mt-2 bg-gray-900 rounded-full" />
                      <p className="mx-2">{EditButtonFn("textList1" + (index6 + 1).toString(), text)}</p>
                    </div>
                  </>
                ))
                : null}
            </div>

            <div className="page-break">
              <div className="w-full space-y-1 page-break">
                <h1 className="text-3xl font-light text-[#212121]">
                  <b>{EditButtonFn("page_2_title_4", "How to use this report")}</b>
                </h1>
              </div>
            </div>

            <div className="space-y-1 text-base">
              {textList2 && textList2.length > 0 ?
                textList2.map((text, index6) => (
                  <>
                    <div key={index6} className={`flex p-3 ${index6 % 2 === 0 ? 'bg-[#F5F5F5]' : 'bg-[#E2E2E2]'}`}>
                      <img src={"/img/icon/dot.png"} className="w-2 h-2 mt-2 bg-gray-900 rounded-full" />
                      <p className="mx-2">{EditButtonFn("textList2" + (index6 + 1).toString(), text)}</p>
                    </div>
                  </>
                ))
                : null}
            </div>
          </div>
        </div>
      </main>

      <main className="p-10 px-6 space-y-6 bg-white page-break">
        <div className="px-12 space-y-6 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-semibold">
              {EditButtonFn(
                "TalentAssessmentReport",
                "Top 5 TalentPlusStrengths Assessment Report"
              )}
            </h1>
            <p className="text-lg font-semibold">
              {(crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : "")} | {createdAt}
            </p>
          </div>

          <div className="w-full space-y-1 page-break">
            <h1 className="text-3xl font-light text-[#212121]">
              {/* <b>{"4 Domains of TalentPlusStrengths themes: "}</b> */}
              <b>
                {EditButtonFn(
                  "domain_4_text",
                  "Domains of TalentPlusStrengths themes"
                )}
              </b>
            </h1>
          </div>

          <div className="w-full space-y-4">
            <div>
              <div class="px-4 py-1 font-semibold bg-gradient-to-r from-[#FFC107] to-[#FFC107]/10">
                <p class="text-md  text-[#212121] tracking-wide">{EditButtonFn("THINKINGTALENTS", "THINKING TALENTS")}</p>
              </div>
              <div className="flex items-start w-full bg-[#ffc107]/10 p-4">
                <div className="text-md">
                  <p className="">
                    {EditButtonFn(
                      "THINKINGDESC",
                      "These talents shape your thinking and perception of the world, allowing you to deliberate on ideas, possibilities, and future actions. They encompass intellectual abilities, which drive human progress. By contemplating these themes, we explore our past, envision the future, and plan in the present. Thinking talents serve as the strategic pathway to our future."
                    )}
                  </p>
                </div>
              </div>
            </div>


            <div>
              <div class="px-4 py-1 font-semibold bg-gradient-to-r from-[#4CAF50] to-[#4CAF50]/10">
                <p class="text-md text-[#FFFFFF] tracking-wide"> {EditButtonFn("RELATINGTALENTS", "RELATING TALENTS")}</p>
              </div>
              <div className="flex items-start w-full bg-[#4CAF50]/10 p-4">
                <div className="text-md">
                  <p className="">
                    {EditButtonFn(
                      "RELATINGDESC",
                      "As social creatures, humans thrive through connections with others. Sharing successes, hardships, and experiences with fellow beings is essential for our well-being and progress. These bonds elevate us, enabling collective achievement of goals, overcoming challenges, and shaping history together."
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div class="px-4 py-1 font-semibold  bg-gradient-to-r from-[#2196F3] to-[#2196F3]/10">
                <p class="text-md text-[#FFFFFF] tracking-wide">{EditButtonFn("ACTINGTALENTS", "ACTING TALENTS")}</p>
              </div>
              <div className="flex items-start w-full bg-[#2196F3]/10 p-4">
                <div className="text-md">
                  <p className="">
                    {EditButtonFn(
                      "ACTINGDESC",
                      "Action talents drive progress by turning ideas into reality. While ideas are important, true advancement occurs through action. Acting talents empower us to translate thoughts into tangible outcomes, driving visible progress and consistent achievement of tasks and goals."
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div class="px-4 py-1 font-semibold bg-gradient-to-r from-[#673AB7] to-[#673AB7]/10">
                <p class="text-md text-[#FFFFFF] tracking-wide">{EditButtonFn("LEADINGTALENTS", "LEADING TALENTS")}</p>
              </div>
              <div className="flex items-start w-full bg-[#673AB7]/10 p-4">
                <div className="text-md">
                  <p className="">
                    {EditButtonFn(
                      "LEADINGDESC",
                      "Leading talents define our ability to lead others toward a shared vision. Leadership isn't just about holding a position; it's a mindset that involves inspiring, aligning, and motivating others to work towards a desired future. Leaders shape a better tomorrow by turning their vision into reality through collaboration and empowerment of others."
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>



      <main className="p-10 px-6 space-y-6 bg-white page-break">
        <div className="px-12 space-y-4 page-break">
          <div className="flex items-center justify-between w-full pt-4 pb-2 border-b text-[#212121]/85 italic">
            <h1 className="text-lg font-semibold">  {EditButtonFn("TalentAssessmentReport", "TalentPlusStrengths Assessment")}</h1>
            <p className="text-lg font-semibold">
              {crrSelectedEmp && crrSelectedEmp.name ? crrSelectedEmp.name : ""} | {createdAt}
            </p>
          </div>

          <h1 class="text-3xl font-light text-[#212121"><b>TalentPlusStrengths 28 themes</b></h1>

          <div className="flex items-center justify-start space-x-6">
            <div className="flex p-2 space-x-2 border rounded-full">
              {getAssessmentReportByEmpIDData &&
                getAssessmentReportByEmpIDData.length > 0
                ? getAssessmentReportByEmpIDData.map((element, index) =>
                  index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1 ? cirleColor(element) : null
                )
                : null}
            </div>

            <div className="flex p-2 space-x-2 border rounded-full ">
              {getAssessmentReportByEmpIDData &&
                getAssessmentReportByEmpIDData.length > 0
                ? getAssessmentReportByEmpIDData.map((element, index) =>
                  getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 <= index + 1 && index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2
                    ? cirleColor(element)
                    : null
                )
                : null}
            </div>

            <div className="flex p-2 space-x-2 border rounded-full ">
              {getAssessmentReportByEmpIDData &&
                getAssessmentReportByEmpIDData.length > 0
                ? getAssessmentReportByEmpIDData.map((element, index) =>
                  getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 <= index + 1 ? cirleColor(element) : null
                )
                : null}
            </div>
          </div>



          <div className="flex space-x-6">
            <div className="w-full divide-y divide-dashed">
              <div className="pb-4 text-base font-normal">
                <h1 className="text-md text-[#2121211] font-medium">
                  {EditButtonFn(
                    "YourTop5TalentPlustheme",
                    "Your Dominant themes are the core qualities that define who you are and contribute to your success. Understanding, nurturing, and regularly using these strengths are key to experiencing a thriving life."
                  )}
                </h1>
              </div>

              <div className="bg-[#F5F5F5] w-full">
                <table class="w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  {getAssessmentReportByEmpIDData &&
                    getAssessmentReportByEmpIDData.length > 0
                    ? getAssessmentReportByEmpIDData.map((element, index) =>
                      index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1 ? (
                        <>
                          <tr key={index}>
                            <td className="p-1 pl-1 border-b border-l border-r border-white">
                              <div className="flex items-center w-44 whitespace-nowrap">
                                <div style={{ background: element && element.colorCode ? element.colorCode : "#DDDDDD", }} className={"w-4 h-4 rounded-full mx-2"} />
                                <h1 style={{ fontWeight: 'bold', color: '#212121' }}>{(index + 1).toString() + ". "}{EditDetailsFn(element, "dimension", element.dimension)}</h1>
                              </div>
                            </td>
                            <td className="w-full pl-3 italic border-b border-l border-r border-white">
                              {EditDetailsFn(element, "desc", element.details.desc)}
                            </td>
                          </tr>
                        </>
                      ) : null
                    )
                    : null}
                </table>
              </div>


              {/* <div className="py-4 text-base font-normal">
                <h1 className="text-md text-[#3D405B] font-medium">
                  {EditButtonFn(
                    "YourNext11TalentPlustheme",
                    "Your Moderate themes are talents that you possess and can draw upon when needed. Mastering these talents allows for further development and growth."
                  )}
                </h1>
              </div>


              <div className="w-full bg-[#F5F5F5]">
                <table class="w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  {getAssessmentReportByEmpIDData &&
                    getAssessmentReportByEmpIDData.length > 0
                    ? getAssessmentReportByEmpIDData.map((element, index) =>
                      getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range1 <= index + 1 && index + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2 ? (
                        <>
                          <tr key={index}>
                            <td className="p-1 pl-1 border-b border-l border-r border-white">
                              <div className="flex items-center whitespace-nowrap w-44">
                                <div style={{ background: element && element.colorCode ? element.colorCode : "#DDDDDD", }} className={"w-4 h-4 rounded-full mx-2"} />
                                <h1 style={{ fontWeight: 'bold', color: '#212121' }}>{(index + 1).toString() + ". "}{EditDetailsFn(element, "dimension", element.dimension)}</h1>
                              </div>
                            </td>
                            <td className="w-full pl-3 italic border-b border-l border-r border-white">
                              {EditDetailsFn(element, "desc", element.details.desc)}
                            </td>
                          </tr>
                        </>
                      ) : null
                    )
                    : null}
                </table >
              </div> */}

              {/* <div className="py-4 text-base font-normal">
                <h1 className="text-md text-[#3D405B] font-medium">
                  {EditButtonFn(
                    "YourLast5TalentPlustheme",
                    "Your Non-Dominant themes are talents that exist but may not be prominent. Learning to manage these talents can help you achieve your goals in life."
                  )}
                </h1>
              </div>

              <div className="w-full bg-[#F5F5F5]">
                <table class="w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  {getAssessmentReportByEmpIDData &&
                    getAssessmentReportByEmpIDData.length > 0
                    ? getAssessmentReportByEmpIDData.map((element, index) =>
                      getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 <= index + 1 ? (
                        <>
                          <tr key={index}>
                            <td className="p-1 pl-1 border-b border-l border-r border-white">
                              <div className="flex items-center whitespace-nowrap w-44">
                                <div style={{ background: element && element.colorCode ? element.colorCode : "#DDDDDD", }} className={"w-4 h-4 rounded-full mx-2"} />
                                <h1 style={{ fontWeight: 'bold', color: '#212121' }}>{(index + 1).toString() + ". "}{EditDetailsFn(element, "dimension", element.dimension)}</h1>
                              </div>
                            </td>
                            <td className="w-full pl-3 italic border-b border-l border-r border-white">
                              {EditDetailsFn(element, "desc", element.details.desc)}
                            </td>
                          </tr>
                        </>
                      ) : null
                    )
                    : null}
                </table >
              </div> */}

            </div>

          </div>

        </div>
        {/* <p>Copyright 2025, HappyPlus, Inc. All rights reserved</p> */}
      </main>
    </>
  );
}
